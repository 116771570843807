<template>
  <div class="row">
    <div class="container">
      <div class="section">

        <!--   Icon Section   -->
        <div class="row">
          <div class="card col s12 homeboy-charcoal-black white-text">
            <div class="col s12 no-padding">
              <div class="icon-block">
                <div class="border-bottom-1 white-border">
                  <h5 class="center">Hello!!!</h5>
                </div>

                <p class="light font-14">Welcome! We are eager to have you join thousands of local businesses on the HomeBoy platform!</p>

                <p class="font-14 font-bold">Registration opens Monday 19th October 1200hrs</p>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
    <div class="container border-top-1 grey-border">
      <div class="section">

        <div class="row">
          <div class="col s12">
            <h4 class="homeboy-font homeboy-charcoal-black-text">Contact Us</h4>
            <div class="col s12">
              <div class="col s12 m10 offset-m1 l8 offset-l2 no-padding">
                <div class="col s4 font-bold right-align">Physical Address</div>
                <div class="col s8 left-align ">
                  Plot 75783,Setlhoa Office Park,Gaborone
                </div>
              </div>
              <div class="col s12 m10 offset-m1 l8 offset-l2 no-padding">
                <div class="col s4 font-bold right-align">
                  Email
                </div>
                <div class="col s8 left-align">admin@homeboybw.com</div>
              </div>
            </div>

          </div>
          <div class="col s12 center">
            <p class="center font-bold">Connect With Us</p>
            <div class="col s4 m4 l4 right-align light">
              <a href="https://www.facebook.com/homeboybw" target="_blank"><img src="../../assets/img/facebook_w_icon.png" width="48" alt="Facebook"></a>
            </div>
            <div class="col s4 m4 l4 center light">
              <a href="https://instagram.com/homeboybw" target="_blank"><img src="../../assets/img/insta_w_icon.png" width="48" alt="Instagram"></a>
            </div>
            <div class="col s4 m4 l4 left-align light">
              <a href="https://twitter.com/homeboybw" target="_blank"><img src="../../assets/img/twitter_w_icon.png" width="48" alt="Twitter"></a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <footer class="page-footer homeboy-gold homeboy-charcoal-black-text font-12">
      <!-- <div class="container">
         <div class="row">
           <div class="col l6 s12">
             <h5 class="white-text">Company Bio</h5>
             <p class="grey-text text-lighten-4">We are a team of college students working on this project like it's our full time job. Any amount would help support and continue development on this project and is greatly appreciated.</p>


           </div>
           <div class="col l3 s12">
             <h5 class="white-text">Settings</h5>
             <ul>
               <li><a class="white-text" href="#!">Link 1</a></li>
               <li><a class="white-text" href="#!">Link 2</a></li>
               <li><a class="white-text" href="#!">Link 3</a></li>
               <li><a class="white-text" href="#!">Link 4</a></li>
             </ul>
           </div>
           <div class="col l3 s12">
             <h5 class="white-text">Connect</h5>
             <ul>
               <li><a class="white-text" href="#!">Link 1</a></li>
               <li><a class="white-text" href="#!">Link 2</a></li>
               <li><a class="white-text" href="#!">Link 3</a></li>
               <li><a class="white-text" href="#!">Link 4</a></li>
             </ul>
           </div>
         </div>
       </div>-->
      <div class="footer-copyright">
        <div class="container">
          Powered by <a class="homeboy-charcoal-black-text" href="https://www.sdkdigilab.com">SDK DIGITAL LAB</a>
        </div>
      </div>
    </footer>
  </div>
</template>

        <script>
// @ is an alias to /src
export default {
  name: "Default-Page",
  components: {

  }
};
</script>
